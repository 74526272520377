/* eslint no-template-curly-in-string: 0*/
const awsConfig = {
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://yf1vayv8t7.execute-api.eu-central-1.amazonaws.com/dev/v1",
  },
  gqlGateway: {
    REGION: "eu-central-1",
    URL: "https://yf1vayv8t7.execute-api.eu-central-1.amazonaws.com/dev/graphql",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_ukjLmWwQE",
    APP_CLIENT_ID: "hl8ps5umqbcrqevrd0r3n7eud",
    IDENTITY_POOL_ID: "eu-central-1:0891e21a-fac7-438e-8cf0-c49465aa53a4",
  },
  i18n: {
    host: "locales.digital-dev-sb.dev.canei.io",
  },
  assets: {
    host: "assets.digital-dev-sb.dev.canei.io",
  },
  registration: {
    FORM_POST: "https://support.digital-dev-sb.dev.canei.io/v1/forms",
    NEXT_PAGE: "https://app.quick-dev-sb.dev.canei.io",
    TO_REFERRER: "https://canei.ag/register-success/",
  },
  payment: {
    publicKey: "pk_test_51Kb2CFJafEP8n6QGsNJNLK9iljof2ilJpV8XPLHv9Q5nRP4vtaY8fvpevUs2wGAb83ZgiREDXqucNGsrm2HthSTf00Jy8h3kW8",
  },
  defaults: {
    SAMPLE_SUSA_URL: "https://datastore.digital-dev-sb.dev.canei.io/records/files/Muster-SuSa.pdf",
    SAMPLE_SUSA_S3: "s3://datastore.digital-dev-sb.dev.canei.io/records/files/Muster-SuSa.pdf",
  },
};

export default awsConfig;
